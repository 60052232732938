const LoadingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1000" height="1000" viewBox="0 0 1000 1000">
      <defs />
      <rect x="0" y="0" width="100%" height="100%" fill="rgba(182,180,180,0)" />
      <g transform="matrix(-55.5868 0 0 55.5868 500.0001 500.0001)" id="288171">
        <path
          fill="rgb(255,255,255)"
          vectorEffect="non-scaling-stroke"
          transform="translate(-9, -7)"
          d="M 5.51948 6 C 5.09553 6 4.77582 5.60882 4.93709 5.21675 C 5.59349 3.62091 7.18466 2.5 9 2.5 C 10.0253 2.5 10.9104 2.82704 11.6234 3.38531 C 12.1332 3.78447 12.9038 3.82496 13.3231 3.33161 L 13.6472 2.95037 C 13.9656 2.57575 13.9695 2.01829 13.6008 1.69305 C 12.413 0.645301 10.7782 0 9 0 C 5.7022 0 2.93708 2.30826 2.21034 5.41908 C 2.13308 5.7498 1.84833 6 1.50871 6 V 6 C 0.920526 6 0.60617 6.69277 0.993492 7.13542 L 2.74742 9.13991 C 3.14584 9.59524 3.85417 9.59524 4.25258 9.13991 L 6.02507 7.1142 C 6.40516 6.67982 6.09667 6 5.51948 6 V 6 Z M 15.2526 4.86009 C 14.8542 4.40476 14.1458 4.40476 13.7474 4.86009 L 11.9579 6.90529 C 11.5844 7.33207 11.8875 8 12.4546 8 V 8 C 12.884 8 13.1997 8.40613 13.0257 8.79871 C 12.322 10.3863 10.8095 11.5 9 11.5 C 7.96239 11.5 7.06833 11.165 6.35092 10.5944 C 5.85234 10.1979 5.09912 10.148 4.67433 10.6228 L 4.36428 10.9693 C 4.03858 11.3333 4.01794 11.8864 4.37311 12.2218 C 5.56303 13.3452 7.20876 14 9 14 C 12.2978 14 15.0629 11.6917 15.7897 8.58092 C 15.8669 8.2502 16.1517 8 16.4913 8 V 8 C 17.0795 8 17.3938 7.30723 17.0065 6.86458 L 15.2526 4.86009 Z"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default LoadingIcon;
