const DownloadIcon = () => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 5.205C13.3247 5.1361 13.3079 5.06822 13.2833 5.0025V4.935C13.2433 4.85788 13.1898 4.787
       13.125 4.725L8.125 0.225C8.05611 0.166662 7.97735 0.118561 7.89167 0.0824999H7.80833C7.7273 0.0435023
       7.64022 0.0156915 7.55 0H2.5C1.83696 0 1.20107 0.237053 0.732233 0.65901C0.263392 1.08097 0 1.65326 0
       2.25V12.75C0 13.3467 0.263392 13.919 0.732233 14.341C1.20107 14.7629 1.83696 15 2.5 15H10.8333C11.4964 15
       12.1323 14.7629 12.6011 14.341C13.0699 13.919 13.3333 13.3467 13.3333 12.75V5.25V5.205ZM8.33333 2.5575L10.4917
       4.5H9.16667C8.94565 4.5 8.73369 4.42098 8.57741 4.28033C8.42113 4.13968 8.33333 3.94891 8.33333
       3.75V2.5575ZM11.6667 12.75C11.6667 12.9489 11.5789 13.1397 11.4226 13.2803C11.2663 13.421 11.0543 13.5 10.8333
       13.5H2.5C2.27899 13.5 2.06702 13.421 1.91074 13.2803C1.75446 13.1397 1.66667 12.9489 1.66667 12.75V2.25C1.66667
       2.05109 1.75446 1.86032 1.91074 1.71967C2.06702 1.57902 2.27899 1.5 2.5 1.5H6.66667V3.75C6.66667 4.34674 6.93006
       4.91903 7.3989 5.34099C7.86774 5.76295 8.50363 6 9.16667 6H11.6667V12.75ZM7.74167 9.2175L7.5 9.4425V7.5C7.5
       7.30109 7.4122 7.11032 7.25592 6.96967C7.09964 6.82902 6.88768 6.75 6.66667 6.75C6.44565 6.75 6.23369 6.82902
       6.07741 6.96967C5.92113 7.11032 5.83333 7.30109 5.83333 7.5V9.4425L5.59167 9.2175C5.43475 9.07627 5.22192 8.99693
       5 8.99693C4.77808 8.99693 4.56525 9.07627 4.40833 9.2175C4.25141 9.35873 4.16326 9.55027 4.16326 9.75C4.16326
       9.94973 4.25141 10.1413 4.40833 10.2825L6.075 11.7825C6.15425 11.8508 6.24771 11.9043 6.35 11.94C6.44975 11.9797
       6.55761 12.0002 6.66667 12.0002C6.77573 12.0002 6.88358 11.9797 6.98333 11.94C7.08563 11.9043 7.17908 11.8508
       7.25833 11.7825L8.925 10.2825C9.08192 10.1413 9.17008 9.94973 9.17008 9.75C9.17008 9.55027 9.08192 9.35873 8.925
       9.2175C8.76808 9.07627 8.55525 8.99693 8.33333 8.99693C8.11142 8.99693 7.89859 9.07627 7.74167 9.2175Z"
        fill="white"
      />
    </svg>
  );
};

export default DownloadIcon;
